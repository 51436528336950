import React, { useState, useEffect } from "react";
import Main from "./Main";

const App = () => {
  // Hardcoded credentials
  const validUsername = "voicebite";
  const validPassword = "V0icebitten!";

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");

  // Check for remembered login on app load
  useEffect(() => {
    const rememberedAuth = localStorage.getItem("rememberedAuthDemo");
    if (rememberedAuth) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = () => {
    if (username === validUsername && password === validPassword) {
      setIsAuthenticated(true);
      setError(""); // Clear any previous errors

      // Save to localStorage if "Remember Me" is checked
      if (rememberMe) {
        localStorage.setItem("rememberedAuthDemo", true);
      }
    } else {
      setError("Invalid credentials. Please try again.");
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("rememberedAuthDemo");
  };

  return (
    <div>
      {isAuthenticated ? (
        <Main onLogout={handleLogout} />
      ) : (
        <div>
          <h1>Login</h1>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <input
            type="text"
            name="username"
            autoComplete="username"
            className="input"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            name="password"
            autoComplete="current-password"
            className="input password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div>
            <input
              type="checkbox"
              id="rememberMe"
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
            />
            <label htmlFor="rememberMe">Remember Me</label>
          </div>
          <button className="button" onClick={handleLogin}>
            Login
          </button>
        </div>
      )}
    </div>
  );
};

export default App;
